var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import MuiLink from '@mui/material/Link';
import { animated, useSpring } from '@react-spring/web';
import isPropValid from '@emotion/is-prop-valid';
import { ReactComponent as ArrowRightIcon } from '@icons/wolfkit-light/arrow-small-right-light.svg';
import Icon from '@shared/ui/icons/Icon';
const LinkComponent = styled(MuiLink, { shouldForwardProp: propName => isPropValid(propName) })((props) => ({
    display: props.display || 'flex',
    alignItems: 'center',
    gap: props.theme.spacing_sizes.s,
    cursor: 'pointer',
    textDecorationColor: props.theme.palette.primary.main,
    textDecorationThickness: '1px',
    justifyContent: props.justifyContent,
    '&:hover': Object.assign({}, !props.withArrow && ({
        fontWeight: 500,
    })),
    '&:active': {
        fontWeight: props.withArrow ? 500 : 600,
    },
}));
const IconContainer = styled.div(() => ({
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    zIndex: 1,
    overflow: 'hidden',
}));
const AnimatedIcon = animated(styled(Icon)(() => ({
    position: 'relative',
    zIndex: 0,
})));
/**
 * component is used for hrefs outside Endrex app
 */
const Link = (_a) => {
    var { onClick = undefined, disabled = false, withArrow = false, children = undefined } = _a, props = __rest(_a, ["onClick", "disabled", "withArrow", "children"]);
    const [{ arrowPositionMultiplier }, api] = useSpring(() => ({
        arrowPositionMultiplier: 1,
        config: {
            mass: 1,
            tension: 320,
            friction: 40,
        },
    }), []);
    const handleClick = (ev) => {
        if (!disabled) {
            onClick === null || onClick === void 0 ? void 0 : onClick(ev);
        }
    };
    const onMouseEnter = () => {
        api.start({
            from: { arrowPositionMultiplier: 1 },
            to: { arrowPositionMultiplier: 0 }
        });
    };
    const onMouseLeave = () => {
        api.start({
            from: { arrowPositionMultiplier: 0 },
            to: { arrowPositionMultiplier: 1 }
        });
    };
    return (_jsxs(LinkComponent
    // eslint-disable-next-line react/jsx-props-no-spreading
    , Object.assign({}, props, { withArrow: withArrow, onClick: handleClick, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, children: [children, withArrow && (_jsx(IconContainer, { children: _jsx(AnimatedIcon, { IconComponent: ArrowRightIcon, style: {
                        left: arrowPositionMultiplier.to(value => `calc(-100% * ${value})`)
                    } }) }))] })));
};
export default Link;
