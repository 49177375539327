export const getTabsStyles = (theme) => ({
    variants: {
        default: {
            borderBottom: `1px solid ${theme.customColors.tabs.border}`,
            padding: `0 ${theme.spacing_sizes.xs * 4}px`,
            selectedTabColor: theme.palette.text.primary,
            tabBackgroundColor: 'transparent',
            indicatorBackgroundColor: theme.customColors.tabs.activeBorder,
            tabSizes: {
                large: {
                    minHeight: 48,
                    tabPadding: `${theme.spacing_sizes.m}px ${theme.spacing_sizes.xs * 0.5}px 
                                 ${theme.spacing_sizes.xs * 1.25}px ${theme.spacing_sizes.xs * 0.5}px`,
                    tabInnerGap: theme.spacing_sizes.s,
                },
                medium: {
                    minHeight: 40,
                    tabPadding: `9px ${theme.spacing_sizes.xs * 0.5}px`,
                    tabInnerGap: theme.spacing_sizes.s,
                },
                small: {
                    minHeight: 32,
                    tabPadding: `${theme.spacing_sizes.s}px ${theme.spacing_sizes.xs * 0.5}px`,
                    tabInnerGap: theme.spacing_sizes.s,
                },
            },
        },
        'chart-tabs': {
            borderBottom: 'unset',
            padding: theme.spacing_sizes.xs * 0.5,
            borderRadius: 6,
            backgroundColor: '#F8F9FA',
            selectedTabColor: '#fff',
            tabMinHeight: 'unset',
            tabMinWidth: 'unset',
            tabHoverColor: theme.palette.primary.main,
            tabBackgroundColor: 'transparent',
            tabSizes: {
                large: {
                    tabPadding: `${theme.spacing_sizes.s}px ${theme.spacing_sizes.m}px`,
                    minHeight: 'unset',
                },
                medium: {
                    tabPadding: `${theme.spacing_sizes.s}px ${theme.spacing_sizes.m}px`,
                    minHeight: 'unset',
                },
                small: {
                    tabPadding: `${theme.spacing_sizes.s}px ${theme.spacing_sizes.m}px`,
                    minHeight: 'unset',
                },
            },
            indicatorBackgroundColor: '',
            indicatorHeight: 'calc(100% - 8px)',
            indicatorTopPosition: 4,
            textZIndex: 2,
        },
    },
});
